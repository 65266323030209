import React from "react";
import { useHistory } from "react-router-dom";
import { CardActionArea } from "@mui/material";
import { Grid, Box, Typography } from "@mui/material";
import { CustomizedOutdoorCard, CustomizedCardMedia, CustomizedBox } from "../../globalstyled/homeStyle";
import { PenIcon } from "../../globalstyled/iconslist";
import Cookies from "js-cookie";
import { postApiCall } from "../../common/axios";
import { API_URL } from "../../common/defines";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
const OutdoorItemCard = (props) => {
  const history = useHistory();
  const champaignPage = () => {
    history.push({ pathname: `/outdoor/${props.campaignName}`, search: `?id=${props.campaignId}` });
  };
  const favouriteHandle = async (e, campaignId, favouriteStatus) => {
    e.preventDefault();
    e.stopPropagation();
    const reqBody = {
      userId: Cookies.get("userId"),
      campaignId,
      favouriteStatus,
    };
    try {
      const response = await postApiCall(API_URL + "favourite/addToFavourite", reqBody);
      if (response.status === false) {
      } else if (response.status === true) {
        props.favouriteChange()
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <div onClick={champaignPage} className="outdoor-item-wrap">
        <CustomizedOutdoorCard>
          <CardActionArea className="homeoutdoorfavouriteWrapeer">
          {Cookies.get("userId") &&
              <>
            {props.isfavourite ? (
              <div
                className="left"
                onClick={(e) => {
                  favouriteHandle(e, props?.campaignId, !props.isfavourite);
                }}
              >
                <FavoriteIcon style={{ color: "#FFD700" }} />
                {/* <StarsIcon style={{color:"#fff"}}/> */}
              </div>
            ) : (
              <div
                className="left"
                onClick={(e) => {
                  favouriteHandle(e, props?.campaignId, true);
                }}
              >
                <FavoriteBorderIcon style={{ color: "#000" }} />
              </div>
            )}
</>}
            <CustomizedBox>
              <CustomizedCardMedia className="outdoor-card-image-fluid" component="img" src={props.campaignImages[0]} alt={props.title} />
            </CustomizedBox>
            <Typography className="cardcontent-outdoor title" variant="h5">
              {props.campaignName}
            </Typography>
            <Typography className="cardcontent-outdoor body" variant="body2">
              {props.mediaOption}
            </Typography>
            <Box className="cardfooter-wrap">
              <PenIcon className="pen-icon-footer" />
              <p className="card-footer-details">
                ₹ {props.minBilling} <span>Min Spend</span>
              </p>
            </Box>
          </CardActionArea>
        </CustomizedOutdoorCard>
      </div>
    </Grid>
  );
};

export default OutdoorItemCard;
