import React from "react";
import "./style.scss";

const HeadingLayout = ({ heading, content }) => {
  return (
    <div className="HeadingContainer">
      <div className="successStoriesHeader">
        <div className="mainHd">{heading}</div>
        <div className="OurService1">{content}</div>
      </div>
    </div>
  );
};

export default HeadingLayout;
