const initialState = {
    location:'',
    media:'',
    type:'',
    tag:'',
    lighting:''
  };
  function dashboardReducer(state = initialState, action) {
    switch (action.type) {
      case "locationFilterTrigger":
        return {
          ...state,
          location:action.payload,
        };
        case "mediaFilterTrigger":
            return {
              ...state,
              media:action.payload,
            };
            case "typeFilterTrigger":
            return {
              ...state,
              type:action.payload,
            };
            case "tagFilterTrigger":
            return {
              ...state,
              tag:action.payload,
            };
            case "lightingFilterTrigger":
            return {
              ...state,
              lighting:action.payload,
            };
      default:
        return state;
    }
  }
  
  export default  dashboardReducer;