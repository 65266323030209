export const testimonialsData = [
  {
    id: 1,
    company: "AVP, Gemini Solutions",
    name: "Akshay Gupta",
    postion: "Assistant Vice President",
    comments: "We have been working with Ishtihar  since their inception and the relationship is strong every year.",
    imgUrl: "./img/gemini.webp",
  },
  {
    id: 2,
    company: "Job Dost",
    name: "Suhas Sonawane",
    postion: "Director Engg.",
    comments: "Ishtihar has been very professional and commited since the very beginning we started them",
    imgUrl: "./img/jobdost.webp",
  },
  {
    id: 3,
    company: "Every Point",
    name: "Joseph Jose",
    postion: "Program manager",
    comments: "A team which is always ready and keen to take up any challenges thrown to them. Highly dedicated, committed and disciplined folks.",
    imgUrl: "./img/everypoint.webp",
  },
];
