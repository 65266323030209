import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PrivateRoute } from "./services/private_route/PrivateRoute";
import "./globalstyled/common/button/main.scss";

import { Home, Outdoor, Profile, Dashboard, Product, About, TermCondition, PrivacyPolicy, Contact, Cart, ItemDetails, Blog, NotFound404, AimlPage } from "./pages/Pages";

import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Alerts from "./components/common/notification/Alert";
import Loader from "./components/common/Loader";
import SharedButton from "./components/common/SharedButton";
import Geolocation from "./components/common/MapGeoLocation";
import AdvertisingPage from "./pages/AdvertisingPage";

function App() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loginReducer.isLoading);
  if (localStorage.getItem("token") === null || localStorage.getItem("token")?.length === 0 || Cookies.get("userId")?.length <= 0 || Cookies.get("userId") === undefined) {
    localStorage.removeItem("islogin");
    localStorage.removeItem("token");
    localStorage.removeItem("avatarImg");
    Cookies.remove("userId");
    dispatch({ type: "logout" });
  }
  useEffect(() => {
    if (isLoading === true) {
      setTimeout(() => {
        dispatch({ type: "isloadingunload" });
      }, 1600);
    }
  }, [isLoading]);

  return (
    <>
      {!isLoading ? (
        <div className="App">
          <Alerts />
          <Header />
          <SharedButton />
          <Geolocation />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about-us" exact component={About} />
            <Route path="/term-and-condition" exact component={TermCondition} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/contact-us" exact component={Contact} />
            <Route path="/outdoor" exact component={Outdoor} />
            <Route path="/outdoor/:slug" exact component={ItemDetails} />
            <Route path="/ai-ml-services" exact component={AimlPage} />
            <PrivateRoute exact path="/profile">
              <Profile />
            </PrivateRoute>
            <PrivateRoute exact path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute exact path="/dashboard/:slug">
              <Product />
            </PrivateRoute>
            <PrivateRoute exact path="/bag">
              <Cart />
            </PrivateRoute>
            <Route path="/pages/:pageid" exact component={AdvertisingPage} />
            <Route path="/blog" component={Blog} />
            <Route path="*" component={NotFound404} />
          </Switch>
          <Footer />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default App;
