import React, { useEffect, useState } from "react";
import { GridCustom } from "../../globalstyled/homeStyle";
import { API_URL } from "../../common/defines";
import { getApiCall } from "../../common/axios";
import { goTop } from "../../components/common/gFun/GFun";
import TitleDescription from "../../components/common/About/TitleDescription";
import Loader from "../../components/common/Loader";

const About = () => {
  const [about, setAbout] = useState({ title: "", description: null });
  const [isLoading, setIsLoading] = useState({ status: true, msg: null });
  useEffect(() => {
    goTop();
  }, []);

  useEffect(() => {
    apiAboutUs();
  }, []);

  const apiAboutUs = async () => {
    try {
      const paramsData = {
        queryParams: {
          pageType: "aboutUs",
        },
      };
      const res = await getApiCall(API_URL + "page/getPagesData", paramsData);
      if (res?.status) {
        setAbout({
          description: res.data.pageContent,
          title: res.data.pageHeading,
        });
        setIsLoading((prev) => {
          return { ...prev, status: false, msg: null };
        });
      } else {
        setIsLoading((prev) => {
          return { ...prev, status: false, msg: "Something went wrong!" };
        });
      }
    } catch (err) {
      console.log("err", err);
      setIsLoading((prev) => {
        return { ...prev, status: false, msg: "Something went wrong!" };
      });
    }
  };

  return (
    <GridCustom>
      {isLoading.status ? (
        <Loader />
      ) : isLoading.status === false && isLoading.msg ? (
        <div style={{ display: "block", marginTop: "12rem", marginBottom: "22rem", width: "100%", textAlign: "center" }}>
          <h3 style={{ color: "#ff00008c" }}>{isLoading.msg}</h3>
        </div>
      ) : (
        <TitleDescription title={about.title} description={about.description} />
      )}
    </GridCustom>
  );
};

export default About;
