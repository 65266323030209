import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, Box, Modal } from "@mui/material";
import { SwapVert as SwapVertIcon } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API_URL } from "../../common/defines";
import { getApiCall } from "../../common/axios";
import {
  ListItemText,
  Checkbox,
  Autocomplete,
  TextField,
  Button,
  FormControl,
  Input,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { CustomMenuItem } from "../../globalstyled/outdoor";
import CityAutoComplete from "./CityAutoComplete";
import AreaAutoComplete from "./AreaAutoComplete";

const FilterList = ({ filterList, updateFilter,yesFilteredFunc }) => {
  const [openModal, setOpenModal] = useState(false);
  
  // State for location 
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [optionTypeLit, setOptionTypeLit] = useState({
    option: "",
    type: "",
    lit: "",
    availableon: "",
  });

  const [visibalilityList, setVisibalityList] = useState({
    location: true,
    option: false,
    type: false,
    tag: false,
    lit: false,
    campaignid: false,
    size: false,
    availableon: false,
  });

  const [searchInputV, setSearchInputV] = useState({
    location: "",
    media: "",
    type: "",
    lits: "",
    cityId: "",
    localityId: "",
    campaginId:"",
    availableon:"",
  });

  useEffect(()=>{
    // console.log('searchInputV',searchInputV)
  },[searchInputV])

  const [cityList, setCityList] = useState("");
  const [mediaValue, setMediaValue] = React.useState("");
  const [availableon, setAvailableOn] = React.useState("");
  const [isCityVisible, setIsCityVisible] = useState(false);
  const [areaList, setAreaList] = useState("");
  const [isAreaVisible, setIsAreaVisible] = useState(false);

  const locationed = filterList?.location.map((name) => {
    return name.name;
  });

  const handleModalOpen = (option) => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  function mediatypeHandle(event) {
    // console.log("event.target.value", searchInputV);
    setSearchInputV({ ...searchInputV, type: parseInt(event.target.value) });
  }

  function litHandle(event) {
    setSearchInputV({ ...searchInputV, lits: parseInt(event.target.value) });
  }
  const radiohandleChange = (event) => {
    setSearchInputV({ ...searchInputV, media: parseInt(event.target.value) });
    setMediaValue(event.target.value);
  };
  
  const availablehandleChange = (event) => {
    // console.log('event = = == =  - - ->',event.target.value)
    setSearchInputV({ ...searchInputV, availableon: event.target.value });
    setAvailableOn(event.target.value);
  };

  const listHandler = (name) => {
    const newList = {
      location: false,
      option: false,
      type: false,
      tag: false,
      lit: false,
      campaignid: false,
      size: false,
      availableon: false,
    };
    newList[name] = true;
    setVisibalityList({ ...newList });
  };

  const applySearchHandler = (e) => {
    // console.log('option sks  - gent anouther', e)
    updateFilter(searchInputV);
  };

  const SearchLocationHandle = (event, newValue) => {
    if (locationed.indexOf(newValue) === -1) {
    } else {
      setSearchInputV({
        ...searchInputV,
        location: locationed.indexOf(newValue) + 1,
      });
      window.history.replaceState(
        null,
        "/outdoor" + window.location.search,
        "/outdoor?location=" + newValue.toLowerCase().replace(/\s/g, " ")
      );
    }
    cityHandleApi(locationed.indexOf(newValue) + 1);
    setValue(newValue);
  };

  const cityHandleApi = async (stateId) => {
    try {
      const queryParams = {
        stateId: stateId,
      };
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const res = await getApiCall(
        API_URL + "search/getCityByStateId",
        paramsData
      );
      // console.log("city list - - - - - ", res.cityData);
      setCityList(res.cityData);
      setIsCityVisible(true);
    } catch (err) {
      // console.log("err", err);
    }
  };

  const AreaHandleApi = async (cityId) => {

    // console.log("city id - - - > ",cityId)

    try {
      const queryParams = {
        cityId: cityId,
      };
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const res = await getApiCall(
        API_URL + "search/getLocalityByCityId",
        paramsData
      );
      // console.log("localitiesData list - - - - - ", res.localitiesData);
      setAreaList(res.localitiesData);
      setIsAreaVisible(true);
    } catch (err) {
      // console.log("err", err);
    }
  };

  const cityIdHandler = (id) => {
    // console.log("id - - - -- - - - -", id);
    setSearchInputV({
      ...searchInputV,
      cityId: id,
    });
  };
  const areaIdHandler = (id) => {
    // console.log("id - - - -- - - - -", id);
    setSearchInputV({
      ...searchInputV,
      localityId: id,
    });
  };

  return (
    <>
      <Grid item xs={11} sm={11} md={9} xl={9}>
        <div className="filter-wrapper">
          <ul>
            <div>
              <li onClick={() => handleModalOpen("location")}>
                <p>Location</p>
                <KeyboardArrowDownIcon />
              </li>
            </div>
            <div>
              <li onClick={() => handleModalOpen("media-option")}>
                <p>Media Options</p>
                <KeyboardArrowDownIcon />
              </li>
            </div>
            <div>
              <li onClick={() => handleModalOpen("media-type")}>
                <p>Media Type</p>
                <KeyboardArrowDownIcon />
              </li>
            </div>
            <div>
              <li onClick={() => handleModalOpen("lit")}>
                <p>Available</p>
                <KeyboardArrowDownIcon />
              </li>
            </div>
            
          </ul>
        </div>
      </Grid>
      <Grid item xs={1} sm={1} md={1} xl={1}>
        <div className="filterIcon">
          <SwapVertIcon onClick={() => handleModalOpen("location")} />
        </div>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="filtered-box">
          <Grid container className="filter-title-container">
            <Grid item sm={12} md={12}>
              <h2 className="filter-title">Filter</h2>
              <CancelIcon
                onClick={() => {
                  setOpenModal(false);
                }}
                className="filter-close-icons"
              />
            </Grid>
          </Grid>
          <Grid container className="selection-filter-container" spacing={1}>
            <Grid item xs={5} sm={6} md={4} className="selected-filter-wrap">
              <ul className="filter-ul">
                <li
                  className={`${visibalilityList.location && "active"}`}
                  onClick={() => listHandler("location")}
                >
                  Location
                </li>
                <li
                  className={`${visibalilityList.option && "active"}`}
                  onClick={() => listHandler("option")}
                >
                  Media Options
                </li>
                <li
                  className={`${visibalilityList.type && "active"}`}
                  onClick={() => listHandler("type")}
                >
                  Media Type
                </li>
                {/* <li
                  className={`${visibalilityList.tag && "active"}`}
                  onClick={() => listHandler("tag")}
                >
                  Tags
                </li> */}
                <li
                  className={`${visibalilityList.lit && "active"}`}
                  onClick={() => listHandler("lit")}
                >
                  Lit/NonLit
                </li>
                <li
                  className={`${visibalilityList.campaignid && "active"}`}
                  onClick={() => listHandler("campaignid")}
                >
                  Campaign ID
                </li>
                <li
                  className={`${visibalilityList.availableon && "active"}`}
                  onClick={() => listHandler("availableon")}
                >
                  Available
                </li>
                {/* <li
                  className={`${visibalilityList.size && "active"}`}
                  onClick={() => listHandler("size")}
                >
                  Size
                </li> */}
              </ul>
            </Grid>

            <Grid item xs={7} sm={6} md={8} className="filter-list-wraper">
              <div
                className={`forlocation notdiv  ${
                  visibalilityList.location && "active"
                }`}
              >
                <Autocomplete
                  disablePortal
                  value={value}
                  onChange={(event, newValue) => {
                    // console.log("new value - - - - -", newValue);
                    SearchLocationHandle(event, newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="location-search-outdoor"
                  size="small"
                  options={locationed || [""]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Type to search State"
                      placeholder=""
                    />
                  )}
                />
                {!isCityVisible ? (
                  ""
                ) : (
                  <CityAutoComplete
                    cityIdHandler={cityIdHandler}
                    cityList={cityList}
                    AreaHandleApi={AreaHandleApi}
                  />
                )}
                {!isAreaVisible ? (
                  ""
                ) : (
                  <AreaAutoComplete
                    areaIdHandler={areaIdHandler}
                    areaList={areaList}
                  />
                )}
              </div>
              <div
                className={`formediaoptions notdiv ${
                  visibalilityList.option && "active"
                }`}
              >
                <Box className="mediacardwrapper">
                  <FormControl className="filterform">
                    <Input
                      className="filtersearchfield"
                      placeholder="Search Media Options"
                      id="media-option-multiple-checkbox"
                      multiple
                      value={optionTypeLit.option}
                      onChange={(e) => {
                        setOptionTypeLit({
                          ...optionTypeLit,
                          option: e.target.value,
                        });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    ></Input>

                    <Box className="radioboxwrapper">
                      <RadioGroup
                        aria-labelledby="media-option-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={mediaValue}
                        onChange={radiohandleChange}
                      >
                        {filterList?.mediaOptions
                          ?.filter((data) =>
                            data.name
                              .toLowerCase()
                              .includes(optionTypeLit.option.toLowerCase())
                          )
                          .map((name, i) => (
                            <CustomMenuItem
                              className="ksdjflkjsdioflskdfjlk"
                              key={i}
                            >
                              <FormControlLabel
                                value={name.id}
                                control={<Radio size="small" />}
                                label={name.name}
                              />
                            </CustomMenuItem>
                          ))}
                      </RadioGroup>
                    </Box>
                  </FormControl>
                </Box>
              </div>
              <div
                className={`formediatype notdiv ${
                  visibalilityList.type && "active"
                }`}
              >
                <Box className="mediacardwrapper">
                  <FormControl className="filterform">
                    <Input
                      className="filtersearchfield"
                      placeholder="Search Media Type"
                      id="media-type-multiple-checkbox"
                      multiple
                      value={optionTypeLit.type}
                      onChange={(e) => {
                        setOptionTypeLit({
                          ...optionTypeLit,
                          type: e.target.value,
                        });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    ></Input>

                    <Box className="radioboxwrapper">
                      {/* {console.log("filter list -> ", filterList)} */}
                      {filterList?.mediaType
                        ?.filter((data) =>
                          data.name
                            .toLowerCase()
                            .includes(optionTypeLit.type.toLowerCase())
                        )
                        .map((name, i) => (
                          <CustomMenuItem key={name.id}>
                            <Checkbox
                              size="small"
                              value={name?.id}
                              onClick={mediatypeHandle}
                            />
                            <ListItemText primary={name?.name} />
                          </CustomMenuItem>
                        ))}
                    </Box>
                  </FormControl>
                </Box>
              </div>
              {/* <div
                className={`fortags notdiv ${visibalilityList.tag && "active"}`}
              >
                <Box className="mediacardwrapper">
                  <FormControl className="filterform">
                    <Input
                      className="filtersearchfield"
                      placeholder="Search Tags"
                      id="tag-option-multiple-checkbox"
                      multiple
                      value={searchInputV.tag}
                      onChange={(e)=>{searchHandler(e,'tag')}}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    ></Input>

                    <Box className="radioboxwrapper">
                      {mediatags.map((name) => (
                        <CustomMenuItem key={name}>
                          <Checkbox
                            size="small"
                            value={name}
                            onClick={tagsHandle}
                          />
                          <ListItemText primary={name} />
                        </CustomMenuItem>
                      ))}
                    </Box>
                  </FormControl>
                </Box>
              </div> */}
              <div
                className={`forlit notdiv ${visibalilityList.lit && "active"}`}
              >
                <Box className="mediacardwrapper">
                  <FormControl className="filterform">
                    <Input
                      className="filtersearchfield"
                      placeholder="Search Lit/NonLit"
                      id="lit-option-multiple-checkbox"
                      multiple
                      value={optionTypeLit.lit}
                      onChange={(e) => {
                        setOptionTypeLit({
                          ...optionTypeLit,
                          lit: e.target.value,
                        });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    ></Input>
                    <Box className="radioboxwrapper">
                      {filterList?.lightingOptions
                        ?.filter((data) =>
                          data.name
                            .toLowerCase()
                            .includes(optionTypeLit.lit.toLowerCase())
                        )
                        .map((name) => (
                          <CustomMenuItem key={name.id}>
                            <Checkbox
                              size="small"
                              value={name?.id}
                              onClick={litHandle}
                            />
                            <ListItemText primary={name?.name} />
                          </CustomMenuItem>
                        ))}
                    </Box>
                  </FormControl>
                </Box>
              </div>
              <div
                className={`forcampaginid notdiv  ${
                  visibalilityList.campaignid && "active"
                }`}
              >
                <Box className="mediacardwrapper">
              <FormControl className="filterform">
                <TextField value={searchInputV.campaginId} onChange={(e)=>{setSearchInputV({
                  ...searchInputV,
                  campaginId: e.target.value,
                });}} id="standard-campaignid" label="Search Campaign ID" variant="standard" className="muitext MuiFormControl-root" 
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                />
                </FormControl>
                </Box>
              </div>

              <div
                className={`foravailableon notdiv ${
                  visibalilityList.availableon && "active"
                }`}
              >
                <Box className="mediacardwrapper">
                  <FormControl className="filterform">
                    <Input
                      className="filtersearchfield"
                      placeholder="Search Available Options"
                      id="media-option-multiple-checkbox"
                      multiple
                      value={optionTypeLit.availableon}
                      onChange={(e) => {
                        setOptionTypeLit({
                          ...optionTypeLit,
                          availableon: e.target.value,
                        });
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    ></Input>

                    <Box className="radioboxwrapper">
                      <RadioGroup
                        aria-labelledby="media-option-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={availableon}
                        onChange={availablehandleChange}
                      >
                        {/* {console.log('location - - >', filterList)} */}
                          {filterList?.availableon
                          ?.filter((data) =>
                            data.name
                              .toLowerCase()
                              .includes(optionTypeLit.availableon.toLowerCase())
                          )
                          .map((name, i) => (
                            <CustomMenuItem
                              className="ksdjflkjsdioflskdfjlk"
                              key={i}
                            >
                              <FormControlLabel
                                value={name.id}
                                control={<Radio size="small" />}
                                label={name.name}
                              />
                            </CustomMenuItem>
                          ))}
                         
                      </RadioGroup>
                    </Box>
                  </FormControl>
                </Box>
              </div>

              {/* <div
                className={`forlocation notdiv  ${
                  visibalilityList.size && "active"
                }`}
              >
                
                <Box className="mediacardwrapper">
              <FormControl className="filterform">
                <TextField id="standard-size" label="Search Size" variant="standard"  
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                />
                </FormControl>
                </Box>
              </div> */}
            </Grid>
          </Grid>
          <Grid container className="footer-filter-container">
            <Grid item xs={12} sm={6} md={6}>
              <Button
                className="clear-all-btn"
                variant="outlined"
                onClick={() => {
                  setMediaValue("");
                  setAvailableOn("");
                  setIsCityVisible(false);
                  setIsAreaVisible(false);
                  handleModalClose();
                  setSearchInputV({
                    location: "",
                    media: "",
                    type: "",
                    lits: "",
                    cityId: "",
                    localityId: "",
                    campaginId:"",
                    availableon:"",
                  });
                  
                  setValue("");
                  yesFilteredFunc(false);
                }}
              >
                Clear All
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Button
                className="apply-btn"
                variant="contained"
                // onChange={}={() => {
                  // }}
                  onClick={(e) => {
                    
                  
                  yesFilteredFunc(true)
                  applySearchHandler(e);
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default FilterList;
