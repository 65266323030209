import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { goTop } from "../../components/common/gFun/GFun";
import { GridCustom } from "../../globalstyled/homeStyle";
import "./blog.scss";
import Hero from "../../components/home/HeroSlider";
import { Grid12All } from "../../components/shared/GridWrap";

const Blog = () => {
  useEffect(() => {
    goTop();
  }, []);
  return (
    <div>
      <GridCustom>
        <Grid container>
          <Grid12All>
            <Hero />
            <Grid12All>
              <Grid container justifyContent="center">
                <Grid12All>
                  <div className="">
                    <h1 className="outdoor-slider-title">Blog</h1>
                    <hr style={{ marginTop: 0 }} />
                  </div>
                  <div className="coming-soon">
                    <h4 className="coming-soon-title">Coming Soon...</h4>
                  </div>
                </Grid12All>
              </Grid>
            </Grid12All>
          </Grid12All>
        </Grid>
      </GridCustom>
    </div>
  );
};

export default Blog;
