import React from "react";
import { Grid } from "@mui/material";
import { Call, WhatsApp, EmailOutlined } from "@mui/icons-material";
const CallMail = () => (
  <div className="engage-wrapper">
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6} className="engage-container">
        <div className="engage">
          <h2 className="title">Engage</h2>
          <h4 className="sub-title">Expert Media Partners</h4>
          <p className="description">
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour passage of Lorem Ipsum
          </p>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <div className="contact-info">
          <h3 className="call-us">Call Us</h3>
          <div className="call">
            <p>
              <span>
                <Call color="primary" />
              </span>
              8924019879
            </p>
            <p>
              <span>
                <WhatsApp color="primary" />
              </span>
              8171642992 (Whatsapp Only)
            </p>
            <p className="time">Monday - Friday | 08:00AM - 06:00PM</p>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <div className="email-info">
          <h3 className="mail-us">Email Us</h3>
          <div className="call">
            <h5>Advertisers</h5>
            <p>
              <span>
                <EmailOutlined color="primary" />
              </span>
              help@Ishtihar.com
            </p>
            <h5>Media Owners</h5>
            <p>
              <span>
                <EmailOutlined color="primary" />
              </span>
              help@Ishtihar.com
            </p>
          </div>
        </div>
      </Grid>
    </Grid>
  </div>
);

export default CallMail;
