const initialState = {
    isCartReload : false,
    data: [],
}


function cartReducer(state=initialState, action){
    switch(action.type){
        case "cartreload":
            return{...state, isCartReload:true};
        case "cartnotreload":
            return {...state, isCartReload:false};
        case 'CARTUPDATE':
            return {...state,data:action.payload}
        default:
            return state;

    }
}

export default cartReducer;