import React from 'react'
import { Link } from "react-router-dom";
import { Breadcrumbs, Box } from "@mui/material";
export const LinK = ({title, classes, link}) => {
    
  return (
    <Link className={classes} to={link}>
        {title}
    </Link>
  )
}

export const BreadcrumbsBox = ({children}) => {
    return(
        <Box className="breadcrumbs-wrapper">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {children}
            </Breadcrumbs>
        </Box>
    )
}

