import React from "react";
import HeadingLayout from "../../../common/HeadingLayout";
import topImg from "./../../../assets/aiml/topimg.png";
import middleimg from "./../../../assets/aiml/middleimg.png";
import bottomimg from "./../../../assets/aiml/bottomimg.png";
import BasicTabs from "../BasicTabs";
import { FaRegSquareCheck } from "react-icons/fa6";
import "./project.scss";
const ProjectsSection = () => {
  const keyfeature = [
    {
      title: "Custom Chat Database Upload: Easily upload your chat logs (from messaging apps, email conversations, or social media archives) to serve as training data.    ",
      icon: <FaRegSquareCheck color="#08AD19" size={20} />,
    },
    {
      title:
        " Behavioral Modeling: The AI analyzes linguistic patterns, tone, and context to construct a detailed digital profile that accurately reflects the individual’s conversational habits. ",
      icon: <FaRegSquareCheck color="#08AD19" size={20} />,
    },
    {
      title: "Personalized Responses: Once trained, the chatbot delivers responses that closely mimic the way the person would speak, creating a deeply personal interaction. ",
      icon: <FaRegSquareCheck color="#08AD19" size={20} />,
    },
    {
      title: " Privacy & Security: All uploaded data is securely processed with user privacy as a priority, ensuring that sensitive personal information remains confidential.",
      icon: <FaRegSquareCheck color="#08AD19" size={20} />,
    },
    {
      title:
        "Continuous Learning: With periodic updates or additional data uploads, the system can refine its responses to better adapt to subtle changes in the individual’s conversational style.   ",
      icon: <FaRegSquareCheck color="#08AD19" size={20} />,
    },
  ];
  const Benefits = [
    {
      title: " Emotional Connection: Provides a comforting and familiar presence by recreating the unique voice of someone special who is no longer available.",
      icon: <FaRegSquareCheck color="#08AD19" size={20} />,
    },
    {
      title: "Memory Preservation: Acts as a digital archive that preserves the conversational essence  of loved ones, keeping their memory alive in an interactive format.",
      icon: <FaRegSquareCheck color="#08AD19" size={20} />,
    },
    {
      title:
        "Customizable Interaction: Offers users the ability to adjust parameters such as tone, humor, and formality, ensuring the chatbot reflects the original personality as closely as desired.",
      icon: <FaRegSquareCheck color="#08AD19" size={20} />,
    },
    {
      title:
        " Versatile Use Cases: Useful for personal reminiscence, therapeutic support, creative writing inspiration, or even for exploring alternative scenarios in role-playing contexts.",
      icon: <FaRegSquareCheck color="#08AD19" size={20} />,
    },
  ];
  return (
    <>
      <div className="dashboard">
        <HeadingLayout
          heading="Success Stories"
          content="At Ishtihar, we don’t just build technology—we create intelligent solutions that drive business transformation. Partner with us to unlock the full potential of AI and digital innovation."
        />

        <BasicTabs>
          <div label="Imitation Game" className="keyfeature">
            <p className="subheading">
              Imitation Game is an innovative AI platform that allows you to recreate the conversational style of loved ones—be they a spouse, friend, relative, or any person you
              miss—by uploading their chat history. Using advanced natural language processing and machine learning techniques, the system learns the unique behavioral patterns,
              vocabulary, and emotional nuances from the provided database.
            </p>
            <img src={topImg} alt="Top Image" width="650px" />
          </div>

          <div label="Key Features" className="keyfeature">
            <div>
              {keyfeature?.map((item) => (
                <div className=" keyfeature-icon">
                  {item?.icon}
                  <p>{item?.title}</p>
                </div>
              ))}
            </div>
            <img src={middleimg} alt="Middle Image" width="650px" />
          </div>

          <div label="Benefits" className="keyfeature">
            <div>
              {Benefits?.map((item) => (
                <div className="  keyfeature-icon">
                  {item?.icon}
                  <p>{item?.title}</p>
                </div>
              ))}
            </div>
            <img src={bottomimg} alt="Bottom Image" />
          </div>
        </BasicTabs>
      </div>
    </>
  );
};

export default ProjectsSection;
