import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./aibanner.scss";

const ServicesBanner = () => {
  return (
    <div className="servicesBanner">
      <div className="bannerLeftPanel">
        <h1 className="bannerHeading">AI ML Development Services</h1>
        <hr className="horizontalLineBanner" />
        <p>
          Our AI/ML Development Services empower businesses with cutting-edge artificial intelligence and machine learning solutions tailored to their unique needs. We specialize
          in developing custom AI models that enhance automation, improve decision-making, and optimize workflows. Our expertise spans machine learning model development, including
          predictive analytics, pattern recognition, and intelligent automation.
        </p>
        <div className="mx-auto">
          <div className="bannerLeft" style={{ marginTop: "50px" }}>
            <button className="enquireNowBtn" size="large">
              <Link to="/contact-us">Enquire Now</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServicesBanner;
