import React from "react";
import "./aiml.scss";
import bgImage from "./../../assets/aiml/aiml-banner-image.png";
import ServicesBanner from "../../components/AiMlComponent/ServicesBanner";
import BrandTrust from "../../components/AiMlComponent/BrandTrust";

const AimlPage = () => {
  const headingData = [
    {
      id: 1,
      heading: "What we Do ",
      subheading: " We'll help you identify areas where agentic AI can make the most significant impact.",
    },
    {
      id: 2,
      heading: "Our Vision ",
      subheading: " Our team will design AI agents that reflect your goals and adapt as your needs evolve.",
    },
    {
      id: 3,
      heading: "Contact Us  ",
      subheading: " Position your organization at the forefront of technological advancement, setting new standards in your industry. ",
    },
  ];
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          width: "100vw",
          height: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
          alignItems: "center",
          position: "relative",
        }}
      >
        <ServicesBanner />
        <div className="bannerInfo">
          {headingData?.map((data, i) => (
            <div className="contentContainer" key={i}>
              <h1>{data?.heading}</h1>
              <p>{data?.subheading}</p>
            </div>
          ))}
        </div>
      </div>

      <div>
        <BrandTrust />
      </div>
    </div>
  );
};

export default AimlPage;
