const initialState = {
    campaignName :''
  };
function CampaignReducer(state = initialState, action) {
  switch (action.type) {
    case "CAMPAIGNDATATRIGGER":
      return {
        ...state,
        campaignName:action.payload,
      };
    default:
        return state;
  }
}


export default CampaignReducer;