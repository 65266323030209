import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import loginReducer from "./reducers/loginReducer";
import outdoorReducer from "./reducers/outdoorReducer";
import UserReducer from './reducers/UserReducer'
import { weekendsVisible, eventsById } from "./reducers/CalenderReducer";
import notificationReducer from "./reducers/notificationReducer";
import outdoorFilterReducer from './reducers/outdoorFilterReducer';
import cartReducer from "./reducers/cartReducer";
import dashboardReducer from './reducers/dashboardReducer'
import CalenderDateReducer from './reducers/CalenderDateReducer'
import CampaignReducer from './reducers/CampaignReducer'
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
    combineReducers({
      notificationReducer,
      CalenderDateReducer,
      CampaignReducer,
      dashboardReducer,
      outdoorFilterReducer,
      loginReducer,
      outdoorReducer,
      weekendsVisible,
      eventsById,
      UserReducer,
      cartReducer
    }),
    composeEnhancers(applyMiddleware(thunk)),
    
  );