import React from "react";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { API_URL } from "../../common/defines";
import { initialValues, validationSchema } from "../../common/validate/contact/Contact";
import { postApiCall } from "../../common/axios";

const ContactField = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      try {
        const details = {
          userName: values?.name,
          userEmail: values?.email,
          message: values?.message,
        };
        apiSendMsg(details);
      } catch (err) {}
      formik.resetForm();
    },
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-error text-err">{formik.errors[field]}</div>;
  };

  const apiSendMsg = async (reqBody) => {
    try {
      const response = await postApiCall(API_URL + "page/sendInquiry", reqBody);
      if (response.status === false) {
      } else if (response.status === true) {
        dispatch({
          type: "notificationTrigger",
          payload: `Hi ${response.data.name}, ${response.message}`,
        });
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  return (
    <div className="form-container">
      <h2 className="contact-title">Contact Us</h2>
      <div className="form-wrap">
        <TextField label="Full Name" variant="standard" id="name" name="name" {...formik.getFieldProps("name")} className="contact-field" />
        {renderErrorMessage("name")}

        <TextField label="Email *" variant="standard" id="email" name="email" {...formik.getFieldProps("email")} className="contact-field" />
        {renderErrorMessage("email")}

        <TextField label="message " variant="standard" type="text" id="message" name="message" multiline rows={3} {...formik.getFieldProps("message")} className="contact-field" />
        {renderErrorMessage("message")}
        <div className="btn-submit">
          <Button
            className="submit-btn"
            variant="contained"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Send Message
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactField;
