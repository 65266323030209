import React from "react";
import Slider from "react-slick";
import "./business.scss";
import HeadingLayout from "../../../common/HeadingLayout";

function Brandcarousel() {
  const ClientsData = [
    {
      img: "/img/PartnerAndClients/starsport.webp",
      alt: "starsport",
    },
    {
      img: "/img/PartnerAndClients/aha.webp",
      alt: "aha",
    },
    {
      img: "/img/PartnerAndClients/iitiim.webp",
      alt: "iitiim",
    },
    {
      img: "/img/PartnerAndClients/hundredMb.webp",
      alt: "hundredMb",
    },
    {
      img: "/img/PartnerAndClients/scanamaze.webp",
      alt: "scanamaze",
    },
    {
      img: "/img/PartnerAndClients/jcpenny.webp",
      alt: "jcpenny",
    },
    {
      img: "/img/PartnerAndClients/preschool.webp",
      alt: "preschool",
    },
    {
      img: "/img/PartnerAndClients/next-gen.webp",
      alt: "next-gen",
    },
    {
      img: "/img/PartnerAndClients/point-precise.png",
      alt: "pointprecise",
    },
  ];

  const settings = {
    dots: false, // Remove dots navigation
    infinite: true,
    speed: 1000, // Adjust speed as needed
    slidesToShow: 5, // Number of slides to show at once
    slidesToScroll: 5,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Adjust autoplay speed as needed
    pauseOnHover: true, // Pause autoplay on hover
    arrows: false, // Remove left and right arrows
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 6,
        },
      },
    ],
  };

  return (
    <div>
      <div className="slider">
        <HeadingLayout heading="Trusted by many Industry Leaders, Startups, and Enterprises" />
      </div>
      <div className="solution123">
        <Slider {...settings}>
          {ClientsData.map((items, index) => (
            <div className="division1" key={index}>
              <img src={items?.img} className="imageTag" alt={items?.alt} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Brandcarousel;
