import React from "react";
import "./testimonial.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { testimonialsData } from "./testimonialsData";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";

import { Pagination, Navigation, Scrollbar, A11y, Mousewheel, EffectCreative } from "swiper";

import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "./swipper.css";
import HeadingLayout from "../../../common/HeadingLayout";

const Testimonials = () => {
  return (
    <div className="TestmoinalsContainer">
      <div className="swipperContent">
        <Swiper
          loop={true}
          slidesPerView={1}
          grabCursor={true}
          navigation={true}
          mousewheel={false}
          modules={[EffectCreative, Pagination, Navigation, Scrollbar, A11y, Mousewheel]}
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              origin: "left center",
              translate: ["-5%", 0, -200],
              rotate: [0, 100, 0],
            },
            next: {
              origin: "right center",
              translate: ["5%", 0, -200],
              rotate: [0, -100, 0],
            },
          }}
          className="mySwiper6"
        >
          {testimonialsData.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="slideContainer">
                <div className="mainHeading">
                  <HeadingLayout heading="What Our Clients Say's About Ishtihar" />
                </div>
                <div className="cardWrapper">
                  <div className="textCard">
                    <div className="imageCard">
                      <div className="imageLeft">
                        <div className="imageIconWrapper">
                          <div className="userImage">
                            <img src={item.imgUrl} alt="UserImage" />
                          </div>
                          <RiDoubleQuotesL fontSize={50} color="#266994" />
                        </div>
                        <h2>{item.name}</h2>
                        <p>{item.postion}</p>
                      </div>
                    </div>
                    <div className="cardContent">
                      <div className="testContent">
                        <h1>{item.company}</h1>
                        <p>{item.comments}</p>
                        <div className="designWrapper">
                          <div className="circleIcon">
                            <div className={`customIcon ${index === 0 ? "active" : "inactive"}`} />
                            <div className={`customIcon ${index === 1 ? "active" : "inactive"}`} />
                            <div className={`customIcon ${index === 2 ? "active" : "inactive"}`} />
                          </div>

                          <RiDoubleQuotesR fontSize={50} color="#266994" />
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonials;
