const initialState = {
  from:false,
  number_of_counts: 0,
  schedules: [
    {
      
    },
  ],
};

function CalenderDateReducer(state = initialState, action) {
  switch (action.type) {
    case "orderupdate":
      const datadd = state;
      datadd.schedules = {
        date : action.payload.start,
        end_date : action.payload.end,
        total_quantity: 1,
      }
      // let istrue = true;
      // datadd.schedules.map((data, i) => {
      //   if (data.date === action.plainEventObject.start) {
      //     istrue = false;
      //     datadd.schedules[i].date = data.date;
      //     datadd.schedules[i].total_quantity = action.plainEventObject.title;
      //     return { ...datadd };
      //   }
      // });
      // if (istrue) {
      //   datadd.schedules.push({
      //     date: action.plainEventObject.start,
      //     total_quantity: action.plainEventObject.title,
      //   });
      // }
      console.log(datadd)
      return { ...state };

    case "ordercreate":
      const dat = state;
      dat.schedules = action.payload;
      return { ...dat };

    case "orderremove":
      // console.log("orderremove",action)
      let removeDate = state;
      let dateee;
      dateee = removeDate.schedules.filter((item) => {
        if (item.date !== action.payload) {
          return { ...item };
        }
      });

      removeDate.schedules = dateee;
      return { ...removeDate };

    case "updatenumberofcount":
      let b = state;
      // console.log('updatenumberofcount state',b)
      if(typeof(action.payload) === "object"){
        if(b.from != true){
          // console.log("updatenumberofcount true", action)
          b.number_of_counts = action.payload.countT;
          b.from = action.payload.from;
          return {...b}
        } else {
          b.number_of_counts = action.payload.countT
          // console.log("false updatenumberofcount else ladder", action , state)
          return {...b}
        }
      }else{
        // console.log("false updatenumberofcount", typeof action.payload)
        b.number_of_counts += action.payload;
        return { ...b };
      }

    case "decreamentnumberofcount":
        let d = state;
        // console.log('decreamentnumberofcount',action.payload.title)
        d.number_of_counts -= parseInt(action.payload.title);
        return { ...d };

    case "cdcleanup":
     
          return {number_of_counts: 0,
            schedules: [
              {
                
              },
            ],};

    default:
      return state;
  }
}

export default CalenderDateReducer;
