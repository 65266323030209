import React from "react";
import "./business.scss";
import generetiveAi from "./../../../assets/aiml/generetive-ai.webp";
import AiSecurity from "./../../../assets/aiml/AiSecurity.webp";
import AiOps from "./../../../assets/aiml/AiOps.webp";
import Aimodel from "./../../../assets/aiml/Aimodel.webp";
import AutomationAi from "./../../../assets/aiml/AutomationAi.webp";
import Smartai from "./../../../assets/aiml/Smartai.webp";
import Recomendationai from "./../../../assets/aiml/Recomendationai.webp";
import Aimodel2 from "./../../../assets/aiml/Aimodel2.webp";
import Predefineai from "./../../../assets/aiml/Predefineai.webp";
import AiOps0 from "./../../../assets/aiml/AiOps0.webp";
import Productai from "./../../../assets/aiml/Productai.webp";
import faceai from "./../../../assets/aiml/faceai.webp";
import Happyclient from "./Happyclient";
import HeadingLayout from "../../../common/HeadingLayout";

const cardData = [
  {
    id: 1,
    vector: generetiveAi,
    Title: "Generative AI",
    description:
      "Elevate your business with Ishtihar's custom Generative AI development services. Access advanced tools like GPT4, GPT3, GPT 3.5, Midjourney, and DALL-E for deep insights into your brand, operations, and data through visual content analysis. Transform your approach to strategy and decision-making with our tailored AI solutions. Partner with us for unparalleled innovation and transformative results.",
  },
  {
    id: 2,
    vector: AiSecurity,
    Title: "AI Security",
    description:
      "Ishtihar's AI security detects and neutralizes threats like malware and phishing attacks. Our advanced algorithms ensure robust cybersecurity through extensive data analysis. Harness human-machine collaboration to safeguard your business efficiently.",
  },
  {
    id: 3,
    vector: AiOps,
    Title: "AIOps",
    description:
      "Experience proactive AI-driven solutions with Ishtihar's AIOps expertise. Optimize operations, cut IT costs, and elevate customer experiences. Empower your organization with cutting-edge services tailored for success.",
  },
  {
    id: 4,
    vector: Aimodel,
    Title: "AI Model Fine Tuning",
    description:
      "Maximize AI capabilities with our fine-tuning service. Our experts optimize pre-trained models to fit your unique needs and data. Boost accuracy and decision-making by tailoring AI models to evolving or specialized use cases. Unleash the potential of finely-tuned intelligence.",
  },
  {
    id: 5,
    vector: AutomationAi,
    Title: "Automation Solutions",
    description:
      "Experience optimized operations with our AI-driven automation solutions. Our team excels in integrating cutting-edge technologies to enhance business accuracy and efficiency. Utilizing RPA and process automation, we streamline operations, minimize repetitive tasks, and deliver faster turnaround times. Benefit from expert solutions tailored for diverse industries. ",
  },
  {
    id: 6,
    vector: Smartai,
    Title: "Smart AI Assistantsand Chatbot",
    description:
      "Tap into the potential of AI with Ishtihar's smart chatbots for websites, apps, and more. These AI-powered bots collect valuable data for enhanced client interactions and improved UX, unlocking your business's true potential.",
  },
  {
    id: 7,
    vector: Recomendationai,
    Title: "Recommendation Engines",
    description:
      "Our expertise in custom recommendation engine development can greatly benefit your business. By implementing our solutions, you enhance customer satisfaction, loyalty, and retention rates. Our accurate algorithms provide personalized suggestions, boosting conversion rates and overall revenue. With our support, streamline your processes and achieve unprecedented growth.",
  },
  {
    id: 8,
    vector: Aimodel2,
    Title: "AI Model Architecting",
    description:
      "Collaborate with Ishtihar's skilled AI team to craft cutting-edge AI models. From selecting neural networks to optimizing hyperparameters, we possess the expertise to translate your business vision into robust software solutions. Address real-world challenges in real-time with our efficient and proven approach.",
  },
  {
    id: 9,
    vector: Predefineai,
    Title: "Predictive modelling",
    description:
      "Using statistical and machine learning techniques, we analyze your historical data to unveil concealed patterns. Our services enable you to accurately predict future outcomes, empowering informed decisions, strategic planning, and staying ahead of emerging trends. Embrace the transformative potential of data-driven foresight with us.",
  },
  {
    id: 10,
    vector: AiOps0,
    Title: "AI-as-a-Service (AIaaS)",
    description:
      "Unleash the potential of AI effortlessly and cost-effectively. With our AIaaS solutions, access scalable AI infrastructure without the burden of expensive hardware and specialized talent. Bid farewell to in-house complexities and embrace the full benefits of AI integration. Unlock innovation and efficiency with our accessible and comprehensive AI-as-a-Service.",
  },
  {
    id: 11,
    vector: Productai,
    Title: "AI Product Development",
    description:
      "At Ishtihar Technologies, we specialize in delivering custom AI solutions to drive innovation and operational efficiency. With a focus on scalability and reliability, our user-friendly applications seamlessly integrate with existing systems, unlocking AI's potential for impactful outcomes in businesses.",
  },
  {
    id: 12,
    vector: faceai,
    Title: "Facial Recognition Software",
    description:
      "Maximize the power of advanced facial recognition software with our expert AI developers. Enhance your existing solutions with new features, enabling real-time identification in both digital images and live scenarios. Trust our dedicated facial recognition software to bolster security measures and safeguard your data effectively.",
  },
];
function BrandTrust() {
  return (
    <>
      <div className="carryheading">
        <HeadingLayout
          heading="Supercharge Your AI Adventure with Our AI/ML Development Solutions!"
          content=" Ishtihar specializes in dependable AI and ML development services, translating your concepts into tangible solutions. Our proficient team excels in crafting intelligent
              ML solutions, unleashing the full power of data to empower businesses in attaining genuine success."
        />

        <div className="cardContainer">
          {cardData.map((card) => (
            <div className="card" key={card.id}>
              <div className="combined">
                <img src={card.vector} alt="" className="imagecss" />
                <h3 className="twistcss">{card.Title}</h3>
              </div>
              <p className="twistcss2">{card.description}</p>
            </div>
          ))}
        </div>
      </div>

      <Happyclient />
    </>
  );
}

export default BrandTrust;
