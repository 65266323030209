import React, { useEffect, useState } from "react";
import { getApiCall } from "../../common/axios";
import { API_URL } from "../../common/defines";
import Cookies from "js-cookie";
import { Grid12All } from "../shared/GridWrap";
import { Grid } from "@mui/material";
import OutdoorItemCard from "../outdoor/OutdoorItemCard";
import Loader from "../../components/common/Loader";

const FavouriteList = () => {
  const [champaignList, setChampaignList] = useState(null);
  const apiGetChampaingList = async () => {
    try {
      const queryParams = {
        userId: Cookies.get("userId"),
        pageNo: 1,
        limit: 20,
      };
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const res = await getApiCall(API_URL + "favourite/getMyFavourites", paramsData);
      setChampaignList(res?.favouriteData);
    } catch (err) {
      console.log("err", err);
    }
  };

  const favouriteChange = () => {
    apiGetChampaingList()
  }

  useEffect(() => {
    apiGetChampaingList();
  },[]);
  return (
    <Grid spacing={2} container>
      {champaignList ? (
        <Grid12All>
          <Grid container>
            {champaignList?.length>0 ? 
            champaignList?.map((list, i) => {
              return <OutdoorItemCard key={list.campaignId} {...list} favouriteChange={favouriteChange} />;
            })
            : <div style={{minHeight:"40vh", display:'flex', margin:'auto', alignItems:'center'}}>
              <p style={{fontSize:"1.6rem", paddingBottom:'1rem'}}>Not Favourites yet</p>
            </div>
            }
          </Grid>
        </Grid12All>
      ) : (
        <Loader />
      )}
    </Grid>
  );
};

export default FavouriteList;
