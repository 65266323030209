import React, { useState, useEffect } from "react";
import "./cart.scss";
import { Grid, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";

import Cookies from "js-cookie";
import { API_URL } from "../../common/defines";
import { getApiCall, postApiCall, deleteApiCall } from "../../common/axios";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { goTop } from "../../components/common/gFun/GFun";
import { useDispatch } from "react-redux";
import ItemCard from "./ItemCard";
import Loader from "../../components/common/Loader";
import DatePickerRange from "./DatePickerRange";
import { Grid12All, GridA } from "../../components/shared/GridWrap";

const Cart = () => {
  const [champaignName, setChampaignName] = useState("");
  const [dayCount, setDayCount] = useState(1);
  const [dateDataCart, setDateDataCart] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleShow = () => setIsOpen(!isOpen);
  const dispatch = useDispatch();
  let history = useHistory();
  const cartReloadHandler = async () => {
    // window.location.reload();
    getApiDetails();
  };

  const champaignNamehandler = (e) => {
    setChampaignName(e.target.value);
  };
  const [totalAmount, setTotalAmount] = useState();
  const [totalAmountObj, setTotalAmountObj] = useState([
    { id: "123", totalprice: 49490 },
    { id: "212", totalprice: 2309 },
  ]);
  const getApiDetails = async () => {
    setIsLoading(true);
    const paramsData = {
      queryParams: { userId: Cookies.get("userId") },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(API_URL + "cart/myCart", paramsData);
    setCardList(response);
    // console.log("total - -- - >", response);
    setTotalAmount(response?.totalCartAmount);
    let fiterData = response?.cartData.map((cart) => {
      return { campaginId: cart.campaginId, totalAmount: cart.totalAmount };
    });
    setTotalAmountObj(fiterData);
    setIsLoading(false);
    return response;
  };

  useEffect(() => {
    goTop();
    getApiDetails();
  }, []);

  useEffect(() => {}, [dayCount]);

  const deleteAllChampaing = async () => {
    try {
      const reqBody = {
        cartId: ``,
        userId: Cookies.get("userId"),
        deleteType: "deleteAll",
      };

      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const response = await deleteApiCall(API_URL + "cart/deleteCart", reqBody, paramsData);
      dispatch({ type: "cartreload" });
      return response;
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  const DeleteAllCart = async () => {
    await deleteAllChampaing();
    await cartReloadHandler();
  };

  const campaignCreatHandle = async () => {
    const reqBody = {
      userId: Cookies.get("userId"),
      orderName: Cookies.get("userName") + "-" + champaignName,
      grandTotal: totalAmount,
      startDate: dateDataCart.start,
      endDate: dateDataCart.end,
    };
    try {
      const response = await postApiCall(API_URL + "checkout/placeOrder", reqBody);
      if (response.status === false) {
        dispatch({
          typed: "error",
          type: "notificationTrigger",
          payload: response.message,
        });
      } else if (response.status === true) {
        dispatch({
          type: "notificationTrigger",
          payload: "Campaign added, Successfully",
        });
        dispatch({ type: "cartreload" });
        history.push("/dashboard");
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
    // console.log('api hit - -->',reqBody)
  };

  const getTotalAmount = (e, id) => {
    let t, total;
    cardList?.cartData?.map((item, i) => {
      if (item.campaginId === id) {
        if (e > item.totalAmount) {
          t = item.totalAmount;
        } else {
          t = 0;
        }
      }
    });
    // total  = (e + cardList.totalCartAmount) - t
    total = e + cardList.totalCartAmount;
    setTotalAmount(cardList?.cartData[0]?.totalAmount * dayCount);
  };

  useEffect(() => {
    let amount = 1;
    if (cardList?.cartData?.length > 0) {
      // if(dayCount>=30){
      //   amount = parseInt(cardList?.cartData[0]?.offer_rate) || 1;
      //   console.log("dayCount -- - >",dayCount, amount)
      // } else{
        amount = cardList?.cartData[0]?.totalAmount || 1;
        // console.log("dayCount -- - >",dayCount, amount)
      // }
    }
    setTotalAmount(amount * dayCount);
  }, [dayCount]);
  // console.log('total amount  - -- ->', totalAmount)
  function fixedNum(num) {
    return parseFloat(num).toFixed(2);
  }

  return (
    <div className="cart-wrapper">
      {isLoading || cardList?.cartData?.length === 0 ? (
        isLoading ? (
          <Grid spacing={4} container className="cart-grid-section">
            <Grid12All classes="cart-empty-section">
              <Loader />
            </Grid12All>
          </Grid>
        ) : (
          <Grid spacing={4} container className="cart-grid-section">
            <Grid12All classes="cart-empty-section">
              <h4>
                <center>Cart is Empty</center>
              </h4>
            </Grid12All>
          </Grid>
        )
      ) : (
        <Grid spacing={4} container className="cart-grid-section">
          <GridA size={[12, 12, 8, 9]}>
            <Box className="">
              <Card>
                <CardContent className="">
                  <Grid spacing={1} container className="cart-head-item">
                    <GridA size={[4, 4, 4, 4]}>
                      <p>Media Name</p>
                    </GridA>
                    <GridA size={[4, 2, 3, 3]} classes="quantity-container">
                      <p>Quantity</p>
                    </GridA>
                    <GridA size={[4, 6, 5, 5]} classes="head-rate-container">
                      <div>
                        <p>Rates</p>
                      </div>
                      <div>
                        <DeleteIcon
                          onClick={() => {
                            DeleteAllCart();
                          }}
                          className="dlt-btn"
                        />
                      </div>
                    </GridA>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
            {cardList?.cartData?.map((list, i) => {
              return (
                <div key={list.id}>
                  <ItemCard dayCount={dayCount} getTotalAmount={getTotalAmount} {...list} cartReloadHandler={cartReloadHandler} />
                  <br />
                </div>
              );
            })}
          </GridA>
          <GridA size={[12, 12, 4, 3]}>
            <Box className="">
              <Card>
                <CardContent className="nameprefixcontainer">
                  <p>{Cookies.get("userName") + "-"}</p>
                  <TextField
                    onChange={champaignNamehandler}
                    value={champaignName}
                    fullWidth
                    id="fullWidth"
                    label="Campaign Name"
                    variant="standard"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        campaignCreatHandle();
                      }
                    }}
                  />
                </CardContent>
              </Card>
              <br />
              <br />
              <DatePickerRange setDateDataCart={setDateDataCart} setDayCount={setDayCount} />
              <br />
              <Card className="mt-3">
                <CardContent>
                  <div className="cart-total-price">
                    <p>
                      Total Price &nbsp;: <span>₹ {fixedNum(totalAmount)}</span>
                    </p>
                  </div>
                </CardContent>
              </Card>
              <Button onClick={campaignCreatHandle} className="mt-3" variant="contained" fullWidth>
                Next
              </Button>
            </Box>
          </GridA>
        </Grid>
      )}
    </div>
  );
};

export default Cart;
