import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

const AreaAutoComplete = ({ areaList, areaIdHandler }) => {
  const [value2, setValue2] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const aread = areaList?.map((name) => {
    return name.localityName;
  });
  useEffect(() => {
    setValue2("");
  }, [areaList]);

  const SearchCityHandle = (event, newValue2) => {
    setValue2(newValue2);
    const getId = areaList?.filter((name) => name.localityName.toLowerCase() === newValue2.toLowerCase());
    areaIdHandler(getId[0].localityId);
    // if (aread.includes(newValue2.toLowerCase()) === -1) {
    // } else {
    //   // console.log('hi ther elsdkfl ex history', window.location.search.includes('&') ===true)
    //   window.history.replaceState(  null ,"/outdoor"+window.location.search, "/outdoor"+((window.location.search.includes('&') ===true) ? window.location.search.split('&')[0] : window.location.search) +"&city="+newValue2.toLowerCase())
    // }
  };
  return (
    <div style={{ marginTop: "20px" }}>
      <Autocomplete
        disablePortal
        value={value2}
        onChange={(event, newValue2) => {
          SearchCityHandle(event, newValue2);
        }}
        inputValue={inputValue2}
        onInputChange={(event, newInputValue) => {
          setInputValue2(newInputValue);
        }}
        id="Locality-search-outdoor"
        size="small"
        options={aread || [""]}
        renderInput={(params) => <TextField {...params} variant="standard" label="Type to search area" placeholder="" />}
      />
    </div>
  );
};

export default AreaAutoComplete;
