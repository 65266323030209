import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Tab, Tabs, Box } from "@mui/material";
import { GridCustom } from "../../globalstyled/homeStyle";
import DashboardTable from "../../components/dashboard/DashboardTable";
import { goTop } from "../../components/common/gFun/GFun";
import "./dashboard.scss";
import TabPanel from "../../components/champaign/TabPanel";
import AllCampaignDownloadBtn from "../../components/dashboard/AllCampaignDownloadBtn";
import MapViewBtn from "../../components/dashboard/MapViewBtn";
import FavouriteList from "../../components/dashboard/FavouriteList";
function a11yProps(index) {
  return {
    id: `champaign-tab-${index}`,
    "aria-controls": `champaign-tabpanel-${index}`,
  };
}
export default function Dashboard() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    goTop();
  }, []);
  return (
    <GridCustom>
      <Grid container>
        <Grid item sm={1} md={1}></Grid>
        <Grid align="center" item xs={12} sm={10} md={10}>
          <Box className="tabs-wrapper">
            <Box className="tab-head">
              <Tabs className="tabs-container" value={value} onChange={handleChange} aria-label="champaign tabs">
                <Tab className="tab-item" label={"My Campaign".toUpperCase()} {...a11yProps(0)} />
                {/* <Tab className="tab-item" label={"Users".toUpperCase()} {...a11yProps(1)} /> */}
                <Tab className="tab-item" label={"All Campaign Download".toUpperCase()} {...a11yProps(1)} />
                <Tab className="tab-item" label={"Map View".toUpperCase()} {...a11yProps(2)} />
                <Tab className="tab-item" label={"Favourite List".toUpperCase()} {...a11yProps(3)} />
              </Tabs>
            </Box>

            <TabPanel campaingtab={'campaingtabcss'} value={value} index={0}>
              <DashboardTable />
            </TabPanel>
            <TabPanel campaingtab={'campaingtabcss'} value={value} index={1}>
              <AllCampaignDownloadBtn />
            </TabPanel>
            <TabPanel campaingtab={'campaingtabcss'} value={value} index={2}>
              <MapViewBtn />
            </TabPanel>
            <TabPanel campaingtab={'campaingtabcss'} value={value} index={3}>
              <FavouriteList />
            </TabPanel>
          </Box>
        </Grid>
        <Grid item sm={1} md={1}></Grid>
      </Grid>
    </GridCustom>
  );
}
