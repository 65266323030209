// import React from 'react'

export const KeyInsight = ({ list }) => {
  return (
    <>
      <div className="heading">
        <h4>Key Insights</h4>
      </div>
      <div className="key-insight">
        <div className="desc-wrapp">
          {list.map((li, i) => {
            return (
              <div key={i + "00"} className="description">
                <h5>{Object.keys(li)}</h5>
                <p>{[Object.values(li)]}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
