import React from "react";
import "./solutionai.scss";
// import { navigate } from "gatsby";
import rent from "./../../../assets/aiml/rent.svg";
import bank from "./../../../assets/aiml/bank.svg";
import growth from "./../../../assets/aiml/growth.svg";
import travel from "./../../../assets/aiml/travel.svg";
import online from "./../../../assets/aiml/online.svg";
import auction1 from "./../../../assets/aiml/auction1.svg";
import adoption from "./../../../assets/aiml/adoption.svg";
import auction2 from "./../../../assets/aiml/auction2.svg";
import logistics from "./../../../assets/aiml/logistics.svg";
import realstate from "./../../../assets/aiml/realstate.svg";
import agriculture from "./../../../assets/aiml/agriculture.svg";
import HeadingLayout from "../../../common/HeadingLayout";
import Testimonials from "../Testimonials";
import Brandcarousel from "../BrandTrust/Brandcarousel";
import ProjectsSection from "../ProjectsSection";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function SolutionAi() {
  // Define an array of objects for industries
  const industries = [
    { name: "Healthcare", image: realstate },
    { name: "On-Demand", image: bank },
    { name: "Education", image: online },
    { name: "Logistics", image: logistics },
    { name: "Travel & Hospitality", image: travel },
    { name: "Agriculture", image: agriculture },
    { name: "Real Estate", image: travel },
    { name: "Retail- E-commerce", image: auction1 },
    { name: "BFSI", image: auction2 },
    { name: "Care (Child, Pet, Old)", image: adoption },
    { name: "Farming", image: growth },
    { name: "Rental", image: rent },
    { name: "Legal", image: online },
  ];

  const handleClick = () => {
    navigate("/aboutus");
  };

  return (
    <>
      <div className="Targetcss">
        <HeadingLayout
          heading="Ishtihar's AI services and cutting-edge ML solutions serve 
        a wide array of industries, offering innovative and effective tools for development."
          content=" Experience excellence with our skilled AI developers,
         offering unmatched AI/ML Development Services across various industries. Our commitment to quality ensures top-tier solutions for our valued clients, tailored to their specific needs."
        />

        <div className="perabolicss">
          {industries.map((industry, index) => (
            <div className={index % 2 === 0 ? "hyperbolacss" : "hyperbolacss23"} key={index}>
              <img src={industry.image} className="imagecss2" />
              <h1 className="heading">{industry.name}</h1>
            </div>
          ))}
        </div>
        <div className="visitcss">
          <button className="buttoncss">
            <Link to="/about-us"> Learn More About Us</Link>
          </button>
        </div>
      </div>
      <div>
        <ProjectsSection />
        {/* <Brandcarousel /> */}
        <Testimonials />
      </div>
    </>
  );
}

export default SolutionAi;
