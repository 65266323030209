import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { goTop } from "../../components/common/gFun/GFun";
import { API_URL } from "../../common/defines";
import { getApiCall } from "../../common/axios";
import { Grid, Box } from "@mui/material";
import { GridCustomItem } from "../../globalstyled/outdoor";
import "./itemdetails.scss";
import ItemDescCard from "../../components/itemdetails/ItemDescCard";
import Loader from '../../components/common/Loader';
import NotFound404 from "../NotFound404";
import { BreadcrumbsBox, LinK } from "../../components/shared/BreadCrumbs";
import { Grid12All } from "../../components/shared/GridWrap";
import Cookies from "js-cookie";

const ItemDetails = () => {
  const [is404, setIs404]=useState(false)
  const [champaignData, setChampaignData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const cId = new URLSearchParams(useLocation().search).get('id');
  const [googleKey,setGoogleKey] = useState(null)

  useEffect(() => {
    apiGoogleApi();
    if(cId===null || cId === undefined || cId === ''){
      setIs404(true)
    }
    goTop();
    apiGetChampaingDetails();
    return()=>{
      setIs404(false)
    }
  }, []);
  
  const apiGetChampaingDetails = async () => {
    setIsLoading(true)
    try {
      const queryParams = {
        userId: Cookies.get("userId"),
        campaginId: cId,
      };
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const res = await getApiCall(
        API_URL + "campagins/getCampaginDetail",
        paramsData
      );
      
      if (res.message === "Data Not Found.") {
        setIs404(true)
        setIsLoading(false);
      } else if (res.status === true) {
        setIsLoading(false);
        setChampaignData(res.campaginData);
        
      }
    } catch (err) {
    }
  }

  const apiGoogleApi = async () => {
    try {

      const res = await getApiCall(
        API_URL + "dashboard/getMapApiKey",
      );
      
      if (res.message === "Data Not Found.") {
      } else if (res.status === true) {
        setIsLoading(false);
        setGoogleKey(res.apiKey);
        
      }
    } catch (err) {
    }
  }

  const favouriteChange = () => {
    apiGetChampaingDetails()
  }
  
  return (
    <>
    {is404
    ?
    <NotFound404/>
    :
    <div>{
      isLoading?<Loader />:
      <Box>
        <GridCustomItem>
          <Grid spacing={2} container>
            <Grid12All>
              <BreadcrumbsBox>
                <LinK title="Home" classes="breakcrumb-link" link="/" />
                <LinK title="Outdoor" classes="breakcrumb-link" link="/outdoor" />
                <LinK title={champaignData?.campaignName} classes="breakcrumb-link active" link="/outdoor" />
              </BreadcrumbsBox>
            </Grid12All>
            <Grid12All>
              <h4 className="title">
              {champaignData?.campaignName}
              </h4>
            </Grid12All>
            {
              champaignData?.campaignId>0&&
              <ItemDescCard {...champaignData} googleKey={googleKey} favouriteChange={favouriteChange} />
            }
          </Grid>
        </GridCustomItem>
      </Box>
    }
    </div>
    }
    </>
  );
};

export default ItemDetails;
