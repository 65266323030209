import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { goTop } from "../gFun/GFun";

const TitleDescription = ({ title, description }) => {
  useEffect(() => {
    goTop();
  }, []);

  return (
    <Grid container spacing={2} className={"about"} minHeight={"500px"}>
      <Grid item xs={12} sm={12}>
        <h1 className={"title"} style={{ paddingLeft: 0, marginTop: ".6rem", marginBottom: "1.6rem" }}> {title} </h1>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Grid>
    </Grid>
  );
};

export default TitleDescription;
