import React from "react";
import CountUp from "react-countup";
import "./business.scss";
import HeadingLayout from "../../../common/HeadingLayout";
import SolutionAi from "../SolutionAi";
function Happyclient() {
  const AboutCounterData = [
    { countValue: "8", heading: "Successful Years" },
    { countValue: "50", heading: "Fit Attendant" },
    { countValue: "300", heading: "Projects Delivered" },
  ];
  return (
    <>
      <HeadingLayout
        heading=" Let’s delve into an engaging and insightful conversation regarding your esteemed project!"
        content="Experience a complimentary consultation and share your unique project vision to transform it into an exceptional digital masterpiece."
      />
      <div className="accuratecss">
        {AboutCounterData.map((items, index) => (
          <div key={index} className="cardComp">
            <p className="bluecss">
              <CountUp start={0} end={parseInt(items?.countValue)} duration={5} />
              <span>+</span>
            </p>
            <h6 className="containercounter">{items?.heading}</h6>
            <hr className="horizontalLine" />
          </div>
        ))}
      </div>
      <SolutionAi />
    </>
  );
}

export default Happyclient;
