import React, { useState, useEffect, Fragment } from "react";
import Card from "@mui/material/Card";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { Box, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../common/defines";
import { postApiCall, deleteApiCall, putApiCall } from "../../common/axios";

import { CustomSlidBoxListing } from "../../globalstyled/homeStyle";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { GridA } from "../../components/shared/GridWrap";
import SliderModal from "../../components/modal/SliderModal";

const ItemCard = ({ title, card_rate, gst, campaginId, id, image, offer_rate, quantity, sub_total, totalAmount, cartReloadHandler, getTotalAmount, dayCount }, props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const handleDetails = () => {
    history.push({
      pathname: `/outdoor/${title}`,
      search: `?id=${campaginId}`,
      champaginId: props.campaignId,
    });
  };

  const [dayValue, setDayValue] = useState(dayCount);

  const copyChampaign = async () => {
    try {
      const reqBody = {
        userId: Cookies.get("userId"),
        campaginId: campaginId,
        quantity: quantity,
        campaginPrice: totalAmount,
      };
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const response = await postApiCall(API_URL + "cart/addToCart", reqBody, paramsData);
      return response;
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  const deleteChampaing = async () => {
    try {
      const reqBody = {
        cartId: `${id}`,
        userId: Cookies.get("userId"),
        deleteType: "deleteById",
      };

      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const response = await deleteApiCall(API_URL + "cart/deleteCart", reqBody, paramsData);
      dispatch({ type: "cartreload" });
      return response;
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };
  const deleteCartApiHandler = async () => {
    await deleteChampaing();
    await cartReloadHandler();
  };

  const dayValueHandler = (e) => {
    setDayValue(dayCount);

    getTotalAmount(offer_rate * e.target.value + offer_rate * e.target.value * 0.18 - totalAmount, campaginId);
  };

  const CartUpdateApi = async (q) => {
    try {
      const reqBody = {
        userId: Cookies.get("userId"),
        cartId: id,
        quantity: q,
        campaginPrice: dayValue >= 1 || q === undefined ? offer_rate * q + offer_rate * q * 0.18 : totalAmount,
      };
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const response = await putApiCall(API_URL + "cart/updateCart", reqBody, paramsData);
      if (response.status === false) {
      } else if (response.status === true) {
        dispatch({
          type: "notificationTrigger",
          payload: `${response.message}`,
        });
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  function fixedNum(num) {
    return parseFloat(num).toFixed(2);
  }

  useEffect(() => {
    setDayValue(dayCount);
  }, [dayCount]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
    <SliderModal campaignImages={image} show={show} handleClose={handleClose} />
    
    <Box className="cart-item-container">
      <Card>
        <CardContent>
          <Grid spacing={1} container>
            <GridA size={[6, 4, 4, 4]}>
              <div className="cart-item-img-wrap customslider" style={{ cursor: "pointer" }}>
                <CustomSlidBoxListing>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    onClick={()=>setShow(true)}
                  >
                    {image.map((slider, id) => {
                      return (
                        <Fragment key={id}>
                          <SwiperSlide>
                            <img className="img-banner" alt={slider} src={slider} />
                          </SwiperSlide>
                        </Fragment>
                      );
                    })}
                  </Swiper>
                </CustomSlidBoxListing>
                <div className="mt-2">
                  <p className="">{title}</p>
                </div>
              </div>
            </GridA>
            <GridA size={[6, 2, 3, 3]} classes="day-field-container">
              <TextField
                disabled
                className="day-field"
                fullWidth
                id="fullWidth"
                type="number"
                label="#Day(s)"
                variant="standard"
                value={dayValue}
                onChange={(e) => dayValueHandler(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    CartUpdateApi(e.target.value);
                  }
                }}
              />
            </GridA>
            <GridA size={[12, 6, 5, 5]}>
              <Grid spacing={1} container className="item-rate-container">
                <GridA size={[6, 6, 6, 6]} classes="item-rate-head">
                  <p className=""> Card Rate </p>
                  <p className=""> Discounted Rate </p>
                  <p className=""> Sub Total </p>
                  <p className=""> GST </p>
                  <p className=""> Total (Incl. Tax) </p>
                </GridA>
                <GridA size={[6, 6, 6, 6]} classes="item-rate-body">
                  <p className="">₹ {card_rate} </p>
                  <p className=""> ₹ {offer_rate}</p>
                  <p className=""> ₹ {dayValue >= 2 || dayValue === undefined ? fixedNum(offer_rate * dayValue) : fixedNum(offer_rate)} </p>
                  <p className=""> ₹ {dayValue >= 2 || dayValue === undefined ? fixedNum(offer_rate * dayValue * 0.18) : fixedNum(gst)}</p>
                  <p className=""> ₹ {dayValue >= 2 || dayValue === undefined ? fixedNum(offer_rate * dayValue + offer_rate * dayValue * 0.18) : fixedNum(totalAmount)} </p>
                </GridA>
              </Grid>

              <div className="modal-footer cardFooter">
                <DeleteIcon onClick={() => deleteCartApiHandler()} className="dlt-btn" />
              </div>
            </GridA>
          </Grid>
        </CardContent>
      </Card>
    </Box>
    </>
  );
};

export default ItemCard;
